import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Seo from "../../components/Seo/seo"

import * as styles from "./FAQ.module.scss"
import { ContentfulFaqPageQuery } from "../../../types/gatsby-graphql"
import ReactMarkdown from "react-markdown"
import SharedHero from "../../components/SharedHero/SharedHero"

const FAQ = () => {
  const data: ContentfulFaqPageQuery = useStaticQuery(graphql`
    query ContentfulFaqPage {
      contentfulFaqPage {
        title
        faQs {
          question
          answer {
            answer
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo title="FAQ" />
      <div className={styles.faq}>
        <SharedHero
          title={data.contentfulFaqPage?.title as string}
          className={styles.faqSharedHero}
        />
        {data.contentfulFaqPage?.faQs?.map(faq => (
          <div className={styles.faqInner}>
            <h2>{faq?.question}</h2>
            <ReactMarkdown className="lineBreak">
              {faq?.answer?.answer as string}
            </ReactMarkdown>
          </div>
        ))}
      </div>
    </>
  )
}

export default FAQ
